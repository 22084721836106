/* 基础接口信息 */
import request from '@request';
import config from '@config';

// https://mx.igming.com/proxy-api/metrics/api/front/report
export function ganaReport(params) {
  return request.$post('/metrics/api/front/report', { ...params, __flag: 'noReport' });
}

// 获取pixel
export function getPixelByChannel(params) {
  return request.$post('/metrics/api/front/queryChannelPixel', params);
}

// app 安装事件上报
export function reportInstallActive(params) {
  return request.$post('/metrics/api/front/app/install', params);
}

/**
 * 根据不同网站获取不同地区的基本信息
 * @param {*} param0
 * @returns
 */
export function GetBaseInfoByUrl() {
  return request.$post('/config/api/front/nation/checkSiteConfig', {
    siteUrl: config._hostName,
  });
}
/**
 * 获取首页banner
 * @param {*} param0
 * { firstCateId, position }
 * @returns
 */
export function GetBannerList(params) {
  return request.$post('/config/api/front/nation/queryBanner', params);
}

/**
 * 查询客服信息
 * @returns {Promise | Promise<unknown>}
 * @constructor
 */
export function GetServiceInfo() {
  return request.$get('/config/api/front/service/queryService');
}

/**
 * 获取站内信列表
 */
export function GetMsgList(obj) {
  return request.$post('/config/api/notice/station/query', obj);
}

/**
 * 删除站内信
 * @param {*} ids
 */
export function DelMsgs(ids) {
  // return request.$get(`/config/api/notice/station/${ids}`)
  return request.$del(`/config/api/notice/station/${ids}`);
}

/**
 * 标记信息为已读
 * @param {*} param0
 */
export function ReadedMsg({ id }) {
  return request.$put(`/config/api/notice/station/read`, { id });
}

/**
 * 获取活动列表
 * @param {*} obj
 */
export function GetActivityList(obj) {
  return request.$post('/activity/api/front/queryActivity', obj);
}

/**
 * 获取html文本
 * @param {string} code
 */
export function GetHtmlTag(code) {
  return request.$get(`/config/api/front/page/${code}`);
}

/**
 * 初始化跑马灯数据
 */
export function GetMarqueeData() {
  return request.$post('/games/api/front/queryMarquee');
}

/**
 * 查询亡灵节活动
 */
export function QueryDeadActivity() {
  return request.$post('/activity/api/queryDeadActivity');
}

/**
 * 获取app版本
 * {"appId":"","channelId":""}
 */
export function getAppVersion(params) {
  return request.$post('/config/api/front/appVersion/current', params);
}

/**
 * 查询供应商列表
 */
export function queryGamesSupplier(params) {
  return request.$get('/games/api/front/queryGamesSupplier', params);
}

/**
 * 查询供应商品牌列表
 */
export function queryGamesSupplierBrand(params) {
  return request.$post('/games/api/front/queryGamesSupplierBrand', params);
}

/**
 * 用户收藏接口
 * 请求参数：{"gameId":游戏ID,favorites:true}
 * favorites:是否收藏
 */
export function favorites(params) {
  return request.$post('/games/api/user/front/favorites', params);
}

/**
 * 注册 firebase message token接口
 */
export function firebaseMessageToken(params) {
  return request.$post('/metrics/api/front/register/token', params);
}

/**
 * 查询底部tabar分类
 */
export function queryNavigator(params) {
  return request.$post('/config/api/front/nation/queryNavigator', params);
}
/**
 * 查询弹窗列表接口
 * 签到活动：activity_list_dialog
 */
export function queryCommonDialogList(params) {
  return request.$get(`/config/api/front/common/config/${params.code}`);
}
/**
 * 注销账号
 */

export const logoff = () => {
  return request.$del('/user/userinfo/delete');
}
