/**
 * @author: sammy
 * @date: 2025-02-20
 * @description: 账户信息 逐步迁移账户金额信息到该store下
 */
import { defineStore } from 'pinia'

export const useAccountStore = defineStore('account', {
	state: () => {
		return {
			// ---------------------------- 邀请
			invite_rebateAmount: 0, //- 邀请-代理金额
			invite_rewardAmount: 0, //- 邀请-奖励金额
			invite_total: 0, //- 邀请-总金额
			// ---------------------------- 活动
			activity_unWithBalance: 0, //- 活动-未提现金额
			activity_withBalance: 0, //- 活动-可提现金额
			activity_principalFlowTotal: 0, //- 活动-流水总额
			acitivity_withdrawalFlowTotal: 0, //- 活动-提现流水总额
		}
	},
	actions: {
		setInviteTotal(value) {
			this.invite_total = value
		},
		setActivityUnWithBalance(value) {
			this.activity_unWithBalance = value
		},
		setActivityWithBalance(value) {
			this.activity_withBalance = value
		},
		setActivityPrincipalFlowTotal(value) {
			this.activity_principalFlowTotal = value
		},
		setAcitivityWithdrawalFlowTotal(value) {
			this.acitivity_withdrawalFlowTotal = value
		}
	},
})